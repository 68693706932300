export default {
  loading: 'Loading...',
  'no-update': 'Already the latest version',
  home: {
    tab: {
      home: 'Home',
      result: 'Result',
      casino: 'Casino',
      scratch: 'Scratch',
      referral: 'Referral',
      me: 'Me',
      live: 'Live',
    },
    scratch: {
      free: 'FREE',
    },
    kerala: {
      title: 'Kerala State Lottery',
      no: 'NO.',
    },
    digit: {
      title: '3 Digit Game',
      booking: 'Time for Next Booking',
      ticket: 'Ticket',
    },
    dice: {
      title: 'Dice Game',
      play: 'Play Now',
    },
    color: {
      title: 'Color Prediction',
    },
    matka: {
      bid: 'BID',
      win: 'WIN',
      open: 'OPEN',
      close: 'CLOSE',
      title: 'Satta Matka',
    },
    'live-casino': {
      title: 'Live Casino',
    },
    tip: {
      closed: '{{name}} is closed now, please choose next game',
      copied: 'Copy successfully, please share with friends',
    },
    label: {
      menu: 'Menu',
      viewAll: 'View All',
      buyNow: 'Buy Now',
      'lucky-spin': 'Lucky Spin',
      rebate: 'Rebate',
      'free-lottery': 'Free Lottery',
      referral: 'Referral',
      referFriend: 'Refer Friend',
      agent: 'Agent',
    },
    luckyspin: {
      add: 'Add',
      spin: 'SPIN',
      won: 'Won',
      winner: 'Big Winners',
      mySpin: 'My Spin',
    },
  },
  me: {
    webview: {
      vip: 'Member Center',
    },
    new: 'NEW',
    user: {
      userId: 'User ID',
      moreExciting: 'More exciting after logging in',
      login: 'Log in',
      loginUpper: 'LOGIN',
    },
    money: {
      totalWallet: 'Total Wallet',
      cashBalance: 'Cash Balance',
      withdrawAmount: 'Withdrawable Amount',
    },
    vip: {
      recharge: 'Recharge',
      move: 'more to reach level',
    },
    bottom: {
      recharge: 'Recharge',
      withdraw: 'Withdraw',
      transfer: 'Transfer',
      proxy: 'Agency Center',
      logout: 'LOG OUT',
      resultHistory: 'Result history',
      myBets: 'My Bets',
      myTransactions: 'My Transactions',
      commission: 'My Commission',
      notify: 'Notifications',
      lang: 'Languages',
      customer: 'Customer service',
      update: 'Update',
      password: 'Password',
    },
    language: {
      switch: 'Switch Language',
    },
    tip: {
      cashTitle: 'Cash Balance:',
      cashContent:
        '"Cash balance" refers to the actual cash funds available in your account that can be used for betting or other transactions.',
      withdrawTitle: 'Withdrawable Amount:',
      withdrawContent:
        '"Withdrawable amount" is the portion of your cash balance that can be withdrawn from your account. It represents the money that has met the wagering requirements, which need to be fulfilled before the funds can be withdrawn.',
    },
  },
  invitation: {
    title: {
      singUp: 'Free lottery tickets',
    },
    label: {
      claim: 'Claim your Ticket',
    },
  },
};
